import Vue from 'vue'
import App from './App.vue'
import VueMqtt from 'vue-mqtt';
import router from './router'
Vue.use(VueMqtt, 'wss://mqtt.hfg.design/mqtt', {clientId: 'lsd.marcrufeis.de-' + parseInt(Math.random() * 100000)});

Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
