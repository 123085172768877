<template>
  <div id="app">
    <div v-show="!doDraw" class="warning">
      <p>This website contains fast flashing images.</p>
      <p>It may cause discomfort and trigger seizures for people with photosensitive epilepsy.</p>
      <p>Viewers discretion ist advised. Safety first!</p>
      <p>Click anywhere to start.</p>
    </div>
    <vue-p5 class="p5" v-show="doDraw" v-on="{setup, draw, mouseclicked}"></vue-p5>
  </div>
</template>

<script>
import VueP5 from 'vue-p5';
export default {
  name: 'App',
  components: {
    "vue-p5": VueP5
  },
  data() {
    return {
      mqttMsg: undefined,
      doDraw: false,
    }
  },
  mqtt: {
    'lsd' (msg) {
      this.mqttMsg = JSON.parse(msg.toString());
    },
  },
  mounted() {
    this.$mqtt.subscribe('lsd')
    this.$mqtt.publish('/laborwoche/test', 'Hello World!')
  },
  methods: {
    setup(sketch) {
      // sketch.createCanvas(100, 100);
      sketch.createCanvas(sketch.displayWidth, sketch.displayHeight, sketch.WEBL);
      sketch.background(0);
      sketch.colorMode(sketch.HSB);
    },
    draw(sketch) {
      if (!this.mqttMsg) {
        sketch.background(0);
        sketch.stroke(255);
        sketch.fill(255);
        sketch.textSize(sketch.displayWidth * 0.05);
        sketch.textAlign('center');
        sketch.text('the show is starting soon!', sketch.displayWidth / 2, sketch.displayHeight / 2);
      }
      if (this.doDraw && this.mqttMsg) {
        const drawColor = sketch.color(sketch.map(this.mqttMsg.cv, 0, 1024, 0, 360), 100, sketch.map(this.mqttMsg.gate, 0, 1024, 0, 100));
        sketch.background(drawColor);
      }
    },
    mouseclicked(sketch) {
      this.$mqtt.publish('/laborwoche/test', 'toggle fullscreen')
      let fs = sketch.fullscreen();
      this.doDraw = !this.doDraw;
      sketch.fullscreen(!fs);
    }
  },
  render(h) {
    return h(VueP5, {on: this});
  }
}
</script>

<style>
* {
  margin: 0px;
  background-color: black;
  color: white;
}

.p5 {
  cursor: none;
}

.warning {
  position: absolute;
  width: 30vw;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: Source Sans Pro;
  font-size: 2vh;
}
</style>
